import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import {
  Section,
  Container,
  Row,
  Column,
  Text,
  Image,
  Link,
} from 'components/atoms';
import Breadcrumbs from 'components/molecules/Breadcrumbs';

import { useWindowSize } from 'utils/windowSizeHook';

const BannerWork = ({ data, className, tagsData, breadcrumbsData }) => {
  const innerWidth = useWindowSize();
  const [isDesktop, setisDesktop] = useState(true);

  if (innerWidth) {
    const newInnerWidth = innerWidth < 1025 ? false : true;
    if (newInnerWidth !== isDesktop) {
      setisDesktop(newInnerWidth);
    }
  }

  const { title, acf, app_work_tag } = data;

  const {
    subtitle,
    description,
    banner,
    year,
    references,
    use_default_image_on_mobile,
    banner_image_mobile,
  } = acf || {};
  const tagsInfo = tagsData.map(edges => edges.node);

  const tags = app_work_tag.map((tagId, id) => {
    const tag = tagsInfo.find(({ wordpress_id }) => wordpress_id === tagId);

    const { path, name } = tag;

    const isLink = path === null;

    const textOrLink = isLink ? (
      <Text>{name}</Text>
    ) : (
      <Link
        className={`banner-work__content__link`}
        href={path}
        isRouter={false}
        target="_blank"
      >
        <Text>{name}</Text>
      </Link>
    );

    return (
      <div className="banner-work__content__tags__link-item" key={id}>
        {textOrLink}
      </div>
    );
  });

  const linkInDescription = description.match(/(<\/a>)/) ? '__with-link' : '';

  const links =
    references &&
    references.map(({ link, text }, id) => (
      <div className="banner-work__content-description__link" key={id}>
        <Link href={link} isRouter={false} target="_blank" rel="nofollow">
          <Text tag="span" className="body-sm body-sm_bold">
            {text}
          </Text>
        </Link>
      </div>
    ));

  const hasBannerContent =
    (description && year) !== '' ||
    app_work_tag.length !== 0 ||
    references !== null;

  const { source_url, alt_text, media_details } =
    !isDesktop && !use_default_image_on_mobile && banner_image_mobile
      ? banner_image_mobile
      : banner;
  const { width, height } = media_details;

  return (
    <Section className={cn('banner-work', [className])}>
      <Container>
        <Row>
          <Column>
            <Text tag="h1" className="banner-work__title">
              {title}
            </Text>
            <Text className="banner-work__subtitle body-lg">{subtitle}</Text>
          </Column>
        </Row>
      </Container>

      <Container type="fluid">
        <Row>
          <div
            className="banner-work__image-container"
            onMouseDown={e => e.preventDefault()}
            onContextMenu={e => e.preventDefault()}
            role="button"
            tabIndex="0"
            style={{ '--banner-work-aspect': `${(height / width) * 100}%` }}
          >
            <Image
              src={source_url}
              alt={alt_text}
              title={alt_text}
              image={media_details}
            />
          </div>
        </Row>
      </Container>

      {hasBannerContent && (
        <Container className="banner-work__content-container">
          <Row className="banner-work__content">
            {(description !== '' || references !== null) && (
              <Column
                col="12"
                lg="6"
                className="banner-work__content-description"
              >
                {description !== '' && (
                  <div
                    className={cn('banner-work__content-description__title', {
                      [`banner-work__content-description__title${linkInDescription}`]: linkInDescription,
                    })}
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                )}
                {references !== null && (
                  <div className="banner-work__content-description__links d-lg-block d-none">
                    {links}
                  </div>
                )}
              </Column>
            )}
            {(year !== '' ||
              references !== null ||
              app_work_tag.length !== 0) && (
              <Column
                col="12"
                lg="3"
                className="offset-lg-3 banner-work__content__tags"
              >
                {(year !== '' || references !== null) && (
                  <div className="banner-work__content__tags__header">
                    {year !== '' && (
                      <Text className="body-sm body-sm_bold">{year}</Text>
                    )}
                    {references !== null && (
                      <div className="d-lg-none">{links}</div>
                    )}
                  </div>
                )}
                {tags}
              </Column>
            )}
          </Row>
          <Breadcrumbs
            {...breadcrumbsData}
            className="breadcrumbs_work"
            isHorizontal={true}
          />
        </Container>
      )}
    </Section>
  );
};

BannerWork.propTypes = {
  data: PropTypes.object.isRequired,
  breadcrumbsData: PropTypes.object,
  className: PropTypes.string,
  tagsData: PropTypes.array,
};

export default BannerWork;
